import {useState, useEffect} from 'react';
import {Contract as MultiContract} from 'ethers-multicall';
import {useStakeContract, useStakeContractV3, useStakeMultiContract, useStakeMultiContractV3} from "./useContract";
import {Contract } from "@ethersproject/contracts";
import {useStakeCount} from "./useStakeCount";
// import {STAKE_ADDRESS, STAKE_V2_ADDRESS} from "../constants/addresses";
import {useWeb3React} from "@web3-react/core";
import {getStakeVersion} from "../functions/stake";
import {getCallProvider} from "../functions/contract";

let cache = new Map<string, Map<string, StakeRecord[] | undefined >>();

export interface StakeRecord {
    lockTimestamp: number;
    unstaked: boolean;
    amount: number;
    lockDays: number;
    contract: MultiContract;
    version: string;
    shareBonus?: number;
    shareLongBonus?: number;
}

export function useStakeRecords(account?: null | string): StakeRecord[] {
    const { chainId } = useWeb3React();

    const stakeV3Count = useStakeCount(useStakeContractV3(), account)

    const stakeInfo: StakeRecord[] = [];
    const [stakesV3, setStakesV3] = useState(stakeInfo);
    const [loading, setLoading] = useState('');

    const stakeV3ContractMulti = useStakeMultiContractV3();

    const stakeV3Contract = useStakeContractV3();
    const callProvider = getCallProvider(chainId, window);

    const getStakeInfo = async (stakeCount: number, setStakes: any, stakeContractMulti: MultiContract | null,
                                stakeContract: Contract | null, account?: null | string) => {
        if (stakeCount > 0 && account && stakeContractMulti && callProvider && stakeContract) {
            let version = getStakeVersion(stakeContract, chainId);
            let accountCache = cache.get(account);

            if (accountCache && accountCache.has(version)) {
                setStakes(accountCache.get(version));
                setLoading('');
            } else {
                let calls = [];
                setLoading('Loading...');
                for (let i = 0; i < stakeCount; i++) {
                    calls.push(stakeContractMulti.stakers(account, i))
                }
                try {
                    const callResults: any = await callProvider.all(calls);
                    const results = callResults.map((value: any, index: any) => {
                        return {...value, contract: stakeContract, id: index, version: version};
                    })

                    setStakes(results);
                    setLoading('');

                    let cacheTemp = new Map<string, StakeRecord[] | undefined >();
                    cacheTemp.set("1", cache.get(account)?.get("1"))
                    cacheTemp.set("2", cache.get(account)?.get("2"))
                    cacheTemp.set("3", cache.get(account)?.get("3"))
                    cacheTemp.set(version, results)
                    cache.set(account, cacheTemp)
                } catch (error) {
                    console.error("Error getting stake info", error)
                }
            }
        }
    }

    useEffect(() => {
        getStakeInfo(stakeV3Count, setStakesV3, stakeV3ContractMulti, stakeV3Contract, account)
    }, [stakeV3Count, account]);

    return [...stakesV3];
}