import { useEffect } from 'react';

export default function useRefreshToken() {
    useEffect(() => {
            const refreshTokens = async () => {
                const refresh = localStorage.getItem('refreshToken');
                const href = window.location.pathname;
                const token = {
                    refresh: refresh
                };
            
                fetch('https://api-fisher.thefishverse.com/rest-auth/token/refresh/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(token)
                })
                .then(response => response.json())
                .then(data => {
                const errors = []
                if(data.access) {
                    localStorage.setItem('accessToken', data.access);
                    localStorage.setItem('items', data.items);
                } else if(!data.access && href != '/') {
                    console.log('Your session expired, please login again.');
                    localStorage.clear();
                } 
                })
                .catch((error) => {
                console.error(error);
                });
            };
            refreshTokens();
    }, []);
}