import {useEffect, useState} from 'react';
import {useMysteryBoxContract, useMysteryBoxMultiContract} from "./useContract";
import {Contract as MultiContract} from 'ethers-multicall';
import {mysteryBoxItem} from "../constants/items";
import {BigNumber} from '@ethersproject/bignumber'
import {NFT} from "../constants/interfaces";
import {useOwnedItems} from "./useOwnedItems";
import {useWeb3React} from "@web3-react/core";
import {getCallProvider} from "../functions/contract";

let cache = new Map<string, NFT[] | undefined >();

export function useOwnedBoxesWallet(account?: null | string): any {
    const { chainId } = useWeb3React();
    const ownedBoxes: NFT[] = [];
    const [loaded, setLoaded] = useState(false);
    const [ownedWallet, setOwnedWallet] = useState(ownedBoxes);
    const mysteryBoxContract = useMysteryBoxContract();
    const ownedTokenIds = useOwnedItems(mysteryBoxContract, account)
    const mysteryBoxContractMulti = useMysteryBoxMultiContract();
    const callProvider = getCallProvider(chainId, window);

    const getOwnedBoxesWallet = async (ownedTokenIds: BigNumber[], mysteryBoxContractMulti: MultiContract | null, account?: null | string) => {
        if (ownedTokenIds.length > 0 && account && mysteryBoxContractMulti && !loaded) {
            let accountCache = cache.get(account);

            if (accountCache) {
                setOwnedWallet(accountCache);
            } else {
                let calls = [];
                for (let i = 0; i < ownedTokenIds.length; i++) {
                    calls.push(mysteryBoxContractMulti.isBoxOpened(ownedTokenIds[i].toNumber()))
                }
                try {
                    const callResults: any = await callProvider.all(calls);
                    setOwnedWallet(formatWallet(ownedTokenIds, callResults));
                    setLoaded(true);
                    cache.set(account, ownedWallet)
                }
                catch (error) {
                    console.error("Error getting box wallet", error)
                }
            }
        }
    }

    const formatWallet = (tokenIds: BigNumber[], closedStatus: any): NFT[] => {
        let wallet: NFT[] = ownedBoxes;
        for (let i = 0; i < tokenIds.length; i++) {
            const newNft = { ...mysteryBoxItem } as NFT;
            newNft.tokenId = tokenIds[i].toNumber();
            newNft.isBox = true;
            newNft.isOpened = closedStatus[i];
            newNft.contract = mysteryBoxContract!.address;
            wallet.push(newNft)
        }
        return wallet;
    }

    useEffect(() => {
        getOwnedBoxesWallet(ownedTokenIds, mysteryBoxContractMulti, account)
    }, [ownedTokenIds, mysteryBoxContractMulti, account]);

    return ownedWallet;
}