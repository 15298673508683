import {Token} from "@sushiswap/core-sdk";


export type AddressMap = { [chainId: number]: string }

export enum ChainId {
  MATIC = 137,
  BSC = 56,
  BSC_TEST = 97,
  OKEX = 66,
}

export const FVS_ADDRESS: AddressMap = {
  [ChainId.MATIC]: '0x72319a036CCb449cffbAc0D6e95dCd5c6E86cd0c',
}

export const CLAIM_V3_ADDRESS: AddressMap = {
  [ChainId.MATIC]: '0x1D79AB0FE4018577C8aFc913872B9D46D91ef155',
}

export const STAKE_V3_ADDRESS: AddressMap = {
  [ChainId.MATIC]: '0x8134F5007249979CcaaaB8DAeFeBe16A2E20aF70',
}

export const HUNTERS_ADDRESS: AddressMap = {
  [ChainId.MATIC]: '0xAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA',
}

export const SWAP_ADDRESS: AddressMap = {
}

export const STAKE_ADDRESS: AddressMap = {
}

export const STAKE_V2_ADDRESS: AddressMap = {
}

export const CLAIM_V2_ADDRESS: AddressMap = {
}

export const NFT_ADDRESS: AddressMap = {
}

export const MB_ADDRESS: AddressMap = {
}

export function getFVSToken(chainId?: ChainId): Token {
  return chainId ? new Token(chainId, FVS_ADDRESS[chainId], 18, 'FVS', 'FishVerse Token')
      : new Token(ChainId.MATIC, FVS_ADDRESS[ChainId.MATIC], 18, 'FVS', 'FishVerse Token')
}

export function getHunterToken(chainId?: ChainId): Token {
  return chainId ? new Token(chainId, HUNTERS_ADDRESS[chainId], 18, 'FRESH', 'FishVerse Reward Token')
      : new Token(ChainId.MATIC, HUNTERS_ADDRESS[ChainId.MATIC], 18, 'FRESH', 'FishVerse Reward Token')
}