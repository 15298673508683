import {BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import "react-dates/lib/css/_datepicker.css";
import "./styles/app.sass";
import Page from "./components/Page";
import NotFound from "./screens/NotFound";
import YourStakes from "./screens/YourStakes";
import {Web3ReactProvider} from '@web3-react/core';
import { Web3Provider } from '@ethersproject/providers'
import useRefreshToken from './hooks/useRefreshToken'

function getLibrary(provider) {
    return new Web3Provider(provider)
}

function App({Component, pageProps}) {
    useRefreshToken();
    return (
        <Web3ReactProvider getLibrary={getLibrary}>
            <Router {...pageProps}>
                <Switch>
                    <Route exact path="/" render={() => {
                        return (<Page>
                                    <Redirect to="/my-stakes"/>
                                </Page>)
                    }}/>
                    
                    <Route exact path="/my-stakes" render={() => (
                        <Page notAuthorized withMainLayout={false}>
                            <YourStakes/>
                        </Page>
                    )}
                    />
                    <Route path="*" render={() => (
                        <Page>
                            <NotFound/>
                        </Page>
                    )}
                    />
                </Switch>
            </Router>
        </Web3ReactProvider>
    );
}

export default App;
