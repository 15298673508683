import {useState, useEffect} from 'react';
import {Contract} from "@ethersproject/contracts";


export function useReservedItems(contract: Contract | null, account?: null | string): number[] {
    const [reservedItems, setReservedItems] = useState<number[]>([]);
    const [loaded, setLeaded] = useState<boolean>(false);

    const getReservedItems = async (reservedItems: number[], contract: Contract | null, account?: null | string) => {
        if (contract && account && !loaded) {
            contract.reservedItemsOfOwner(account)
                .then((value: any) => {
                    setReservedItems(value);
                    setLeaded(true);
                })
                .catch((e: any) => {
                    console.log("useReservedItems error", e)
                })
        }
    }

    useEffect(() => {
        getReservedItems(reservedItems, contract, account)
    }, [reservedItems, contract, account]);

    return reservedItems;
}