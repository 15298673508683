import {useState, useEffect} from 'react';
import {Contract as MultiContract} from 'ethers-multicall';
import {useNFTMultiContract, useNFTContract} from "./useContract";
import {useWeb3React} from "@web3-react/core";
import { BigNumber } from '@ethersproject/bignumber'
import {NFT} from "../constants/interfaces";
import {useOwnedItems} from "./useOwnedItems";
import {formatOwnedWallet, getCallProvider, getProvider} from "../functions/contract";


let cache = new Map<string, NFT[] | undefined >();

export function useOwnedNFTWallet(account?: null | string): any {
    const { chainId } = useWeb3React();
    const ownedNFTs: NFT[] = [];
    const [loaded, setLoaded] = useState(false);
    const [ownedWallet, setOwnedWallet] = useState(ownedNFTs);
    const nftContract = useNFTContract();
    const ownedTokenIds = useOwnedItems(nftContract, account);
    const nftContractMulti = useNFTMultiContract();
    const callProvider = getCallProvider(chainId, window);

    const getOwnedNFTWallet = async (ownedTokenIds: BigNumber[], nftContractMulti: MultiContract | null, account?: null | string) => {
        if (ownedTokenIds.length > 0 && account && nftContractMulti && !loaded && ownedWallet.length == 0) {
            let accountCache = cache.get(account);
            if (accountCache) {
                setOwnedWallet(accountCache);
            } else {
                let calls = [];
                for (let i = 0; i < ownedTokenIds.length; i++) {
                    calls.push(nftContractMulti.tokenItem(ownedTokenIds[i].toNumber()))
                }
                try {
                    const callResults: any = await callProvider.all(calls);
                    setOwnedWallet(formatOwnedWallet(ownedTokenIds, callResults, nftContract!.address));
                    setLoaded(true);
                    cache.set(account, ownedWallet)
                }
                catch (error) {
                    console.error("Error getting owned wallet", error)
                }
            }
        }
    }
    useEffect(() => {
        getOwnedNFTWallet(ownedTokenIds, nftContractMulti, account)
    }, [ownedTokenIds, nftContractMulti, account]);
    return ownedWallet;
}