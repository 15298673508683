import itemsJson from '../constants/json/items.json';
import {NFT} from "./interfaces";


export let itemArray: NFT[] = itemsJson;

let itemIdMap = new Map<number, NFT>();
for (let i = 0; i < itemArray.length; i++) {
    itemIdMap.set(itemArray[i].id, itemArray[i])
}

let itemContentIpfsMap = new Map<string, NFT>();
for (let i = 0; i < itemArray.length; i++) {
    itemContentIpfsMap.set(itemArray[i].content_ipfs, itemArray[i])
}

let mysteryBoxItem = {
    "name": "Prime Mystery Box",
    "description": "FishVesre Prime Mystery Box",
    "rarity": "LEGENDARY",
    "category": "MISC",
    "image": "https://a.oxcdn.lt/metashooter_api/public/media/48bfa49e31e16b769a0a24cc22187b923920c654.jpg",
    "animation": "https://a.oxcdn.lt/metashooter_api/public/media/5c88bd5f44a7ff8e253a8d9a6b3eb39308b97aca.mp4",
    "content_ipfs": "https://metashooter.mypinata.cloud/ipfs/Qma6zyKW2jV1QnEKU4YBhicUdiTS7fS8MYrNhVbNGA9Sfq",
};

let commonMysteryBoxItem = {
    "name": "Common Mystery Box",
    "description": "FishVesre Common Mystery Box",
    "rarity": "COMMON",
    "category": "MISC",
    "image": "https://a.oxcdn.lt/metashooter_api/public/media/c65a0410071b0c650d2245672d8d4c048b1389a2.jpg",
    "animation": "",
    "content_ipfs": "https://metashooter.mypinata.cloud/ipfs/Qmd9skXYgbrLg8czYa1g3HzaGVvdFgUz2uZYqTNQWdrn8x"
};

export {itemIdMap, itemContentIpfsMap, mysteryBoxItem, commonMysteryBoxItem};