import {useState, useEffect} from 'react';
import {Contract} from "@ethersproject/contracts";
import { BigNumber } from '@ethersproject/bignumber'


export function useOwnedItems(contract: Contract | null, account?: null | string): BigNumber[] {
    const [ownedTokenIds, setOwnedTokenIds] = useState<BigNumber[]>([]);
    const [loaded, setLeaded] = useState<boolean>(false);

    const getOwnedItems = async (contract: Contract | null, account?: null | string) => {
        if (contract && account && !loaded && ownedTokenIds.length == 0) {
            contract.walletOfOwner(account)
                .then((value: any) => {
                    setOwnedTokenIds(value);
                    setLeaded(true);
                })
                .catch((e: any) => {
                    console.log("useOwnedItems error", e)
                })
        }
    }

    useEffect(() => {
        getOwnedItems(contract, account)
    }, [contract, account]);

    return ownedTokenIds;
}