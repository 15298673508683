import React, {useState, useEffect} from 'react';
import styles from "./Staking.module.sass";
import cn from "classnames";
import {useWeb3React} from "@web3-react/core";
import {useTotalStaked} from "../../hooks/useTotalStaked";
import {useStakeRecords} from "../../hooks/useStakeRecords";
import {useStakerRewardPending} from "../../hooks/useStakerRewardPending";
import {calculateStakeRewardStats, calculateStakeStats} from "../../functions/stake";
import Tabs from "./Tabs"
import {useClaimContract} from "../../hooks/useContract";
import {useStakeRewardRecords} from "../../hooks/useStakeRewardRecords";
import {formatEther} from "@ethersproject/units";
import {CLAIM_V2_ADDRESS, CLAIM_V3_ADDRESS} from "../../constants/addresses";
import ComingSoon from '../../components/ComingSoon';
import Title from "../../components/Title";

const StakingMain = () => {
    const { account, active } = useWeb3React();
    const stakeRecords = useStakeRecords(account);
    const { lastDayReward } = calculateStakeStats(stakeRecords);
    const stakeRewardRecords = useStakeRewardRecords(account);
    const { currentReward } = calculateStakeRewardStats(stakeRewardRecords);
    const { totalShares, totalStaked } = useTotalStaked(account);
    const claimV2Contract = useClaimContract(CLAIM_V2_ADDRESS);
    const claimV3Contract = useClaimContract(CLAIM_V3_ADDRESS);
    const [stakerRewardPendingV2, setStakerRewardPendingV2] = useState("0");
    const [stakerRewardPendingV3, setStakerRewardPendingV3] = useState("0");
    let initialStakerRewardPendingV2 = useStakerRewardPending(claimV2Contract, account);
    let initialStakerRewardPendingV3 = useStakerRewardPending(claimV3Contract, account);
    let totalPendingReward = (+formatEther(stakerRewardPendingV2)) + (+formatEther(stakerRewardPendingV3));

    useEffect(() => {
        setStakerRewardPendingV2(initialStakerRewardPendingV2);
        setStakerRewardPendingV3(initialStakerRewardPendingV3);
    }, [initialStakerRewardPendingV2, initialStakerRewardPendingV3]);

    const claimRewards = () => {
        if (claimV2Contract && account && stakerRewardPendingV2 != "0"){
            claimV2Contract.claim(account)
                .then((value: any) => {
                    setStakerRewardPendingV2("0")
                    console.log("Success claiming V2")
                    claimV3();
                })
                .catch((e: any) => {
                    console.log("Error on claiming rewards V2", e)
                })
            return
        }
        claimV3();
    };

    const claimV3  = () => {
        if (claimV3Contract && account && stakerRewardPendingV3 != "0"){
            claimV3Contract.claim(account)
                .then((value: any) => {
                    setStakerRewardPendingV3("0")
                    console.log("Success claiming V3")
                })
                .catch((e: any) => {
                    console.log("Error on claiming rewards V3", e)
                })
        }
    }
    return (
        <div className={styles.section}>
        <div className={cn(styles.statsSection)}>
        <Title>Your stakings</Title>
            <div className={cn(styles.statsContainer)}>
                <div className={cn(styles.colum)}>
                    <p>Currently staked</p>
                    <div className={cn(styles.price)}>
                        <h5 className={cn(styles.whitefont)}>{+(totalStaked).toFixed(3)} FVS</h5>
                    </div>
                </div>
                <div data-tip={"Staked value + estimated rewards for staking period."} className={cn(styles.colum)}>
                    <p>Est. total value</p>
                    <div className={cn(styles.price)}>
                        <h5 className={cn(styles.whitefont)}>{+(totalShares).toFixed(3)} FVS</h5>
                    </div>
                </div>
                <div className={cn(styles.colum)}>
                    <p>Last day profit</p>
                    <div className={cn(styles.price)}>
                        <h5 className={cn(styles.greenfont)}>{+(lastDayReward).toFixed(3)} FVS</h5>
                    </div>
                </div>
                <div className={cn(styles.colum)}>
                    <p>Total claimed rewards</p>
                    <div className={cn(styles.price)}>
                        <h5 className={cn(styles.greenfont)}>{(+formatEther(currentReward)).toFixed(3)} FVS</h5>
                    </div>
                </div>
            </div>
        </div>
        { (totalPendingReward > 0) ?
            <button
                className={styles.claimRewards} style={{cursor:"pointer"}}
                data-tip={`You can claim ${totalPendingReward.toFixed(3)} FVS`}
                onClick={() => claimRewards()}
                >Claim rewards
            </button>
        :
            <button
                className={styles.claimRewards}
                data-tip={"You have no rewards to be claimed."}
                >Claim rewards
            </button>
        }

        <Tabs />
    </div>
    );
};

export default StakingMain;