import React, { useState } from "react";

const style = {
    "color": "#FF8282",
    "cursor": "pointer",
    "textAlign": "left",
    "padding": "12px 15px",
    "fontWeight": "600"
}

const back = <svg style={{marginRight: "10px"}} width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" clipRule="evenodd" d="M8.25722 6.0545C7.91904 5.74233 7.39182 5.76342 7.07965 6.1016L4.38734 9.01823C4.09268 9.33745 4.09267 9.82948 4.38733 10.1487L7.07965 13.0654C7.39181 13.4036 7.91903 13.4247 8.25721 13.1125C8.5954 12.8003 8.61649 12.2731 8.30432 11.9349L6.90298 10.4168L14.9997 10.4168C15.4599 10.4168 15.833 10.0437 15.833 9.58347C15.833 9.12323 15.4599 8.75014 14.9997 8.75014L6.90301 8.75014L8.30432 7.23207C8.61649 6.89389 8.5954 6.36667 8.25722 6.0545Z" fill="#FF8282"/></svg>



const SignOut = (props) => {
  const LogOut = async () => {
    fetch('https://api-fisher.thefishverse.com/rest-auth/logout/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
    })
    .then(response => response.json())
    .then(data => {
        localStorage.clear()
        window.location.href = "/"
    })
  }

  return (
    <>
        <button onClick={e => LogOut()} style={style}>{ back }Sign out</button>
    </>
  );
};

export default SignOut;