import React, { useState, useEffect } from "react";
import {useWeb3React} from "@web3-react/core";
import cn from "classnames";
import styles from "./AccountInfo.module.sass";
import {useBalance} from "./../../../hooks/useBalance";
import {formatEther} from "@ethersproject/units";
import {getNetworkName} from "../../../constants/networks"
import ReactTooltip from 'react-tooltip';

const AccountInfo = () => {
  const [visible, setVisible] = useState(false);
  const {account, chainId, active} = useWeb3React();
  const balance = useBalance(account);
  const username = localStorage.getItem('username')
  const token = localStorage.getItem('accessToken')
  const formatedBalance = (formatEther(balance.toString()) * 1).toFixed(1);
  const expSvg = <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle cx="9.5" cy="9.5" r="9" stroke="white"/>
  <path d="M4.80273 5.89062L6.31152 8.4834L7.82031 5.89062H9.23145L7.08301 9.41113L9.28516 13H7.85938L6.31152 10.3584L4.76367 13H3.33301L5.54004 9.41113L3.38672 5.89062H4.80273ZM12.8887 10.3486H11.0381V9.37695H12.8887C13.2109 9.37695 13.4714 9.32487 13.6699 9.2207C13.8685 9.11654 14.0133 8.97331 14.1045 8.79102C14.1989 8.60547 14.2461 8.39388 14.2461 8.15625C14.2461 7.93164 14.1989 7.72168 14.1045 7.52637C14.0133 7.3278 13.8685 7.16829 13.6699 7.04785C13.4714 6.92741 13.2109 6.86719 12.8887 6.86719H11.4141V13H10.1885V5.89062H12.8887C13.4388 5.89062 13.9059 5.98828 14.29 6.18359C14.6774 6.37565 14.972 6.64258 15.1738 6.98438C15.3757 7.32292 15.4766 7.71029 15.4766 8.14648C15.4766 8.60547 15.3757 8.99935 15.1738 9.32812C14.972 9.6569 14.6774 9.90918 14.29 10.085C13.9059 10.2607 13.4388 10.3486 12.8887 10.3486Z" fill="white"/>
  </svg>;
  const editSvg = <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10.2801 0.00135595C10.0544 0.00386711 9.82983 0.0831784 9.65362 0.263878L4.60041 5.4429L4.58673 5.45692C4.44617 5.5942 4.41973 5.69392 4.36267 5.83192C4.30326 5.97548 4.24844 6.14885 4.20261 6.34284C4.11094 6.7308 4.05653 7.20657 4.1386 7.6927C4.16953 7.87351 4.31887 8.02659 4.49526 8.05828C4.96951 8.1424 5.43378 8.08663 5.81228 7.99267C6.00153 7.94569 6.16608 7.8895 6.30613 7.82861C6.44067 7.77012 6.53815 7.7381 6.67198 7.59423C6.67749 7.58827 6.68004 7.5866 6.68566 7.58011L11.7433 2.40108C12.1462 1.98821 12.0413 1.33322 11.661 0.943457L11.0757 0.343509C10.8856 0.148581 10.6335 0.0244909 10.3761 0.00137475C10.3439 -0.00155508 10.3123 0.00106085 10.28 0.00137475L10.2801 0.00135595ZM10.2755 0.901304C10.2724 0.904443 10.3471 0.871588 10.4538 0.981034C10.6488 1.18276 10.8463 1.38178 11.0437 1.58098C11.1505 1.69043 11.1184 1.76691 11.1215 1.76377L10.8289 2.06375L9.98744 1.19666L10.2755 0.901304ZM9.36547 1.83398L10.2069 2.70107L6.07303 6.93804C6.08232 6.9236 6.02862 6.97058 5.96329 6.99893C5.87622 7.03681 5.75209 7.08002 5.60664 7.11612C5.41769 7.16299 5.19965 7.18455 4.98477 7.18643C4.98691 6.96723 5.00784 6.74634 5.05336 6.55372C5.08858 6.40462 5.13533 6.2728 5.17229 6.18344C5.19331 6.1328 5.21179 6.10193 5.21342 6.0944L9.36547 1.83398ZM1.46331 2.10111C0.66026 2.10111 0 2.77788 0 3.601V10.5002C0 11.3233 0.66026 12 1.46331 12H8.19474C8.99778 12 9.65804 11.3232 9.65804 10.5002V6.15074C9.66131 5.91302 9.45103 5.69434 9.219 5.69434C8.98707 5.69434 8.77668 5.91302 8.77994 6.15074V10.5002C8.77994 10.8404 8.52648 11.1002 8.19463 11.1002H1.4632C1.13135 11.1002 0.87789 10.8404 0.87789 10.5002V3.601C0.87789 3.26085 1.13135 3.00106 1.4632 3.00106H6.2922C6.52412 3.00441 6.73747 2.78886 6.73747 2.55114C6.73747 2.31341 6.52412 2.09787 6.2922 2.10122L1.46331 2.10111Z" fill="#0D5590"/>
  </svg>;  
  const fvs = <img height="20" src="/images/FVS-coin.png" />
  const salt = <img height="20" src="/images/SALT-coin.png" />
  const fresh = <img height="20" src="/images/FRESH-coin.png" />
  const [usernameEdit, setUsernameEdit] = useState(username);
  const [huntersBalance, setHuntersBalance] = useState(0);
  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState(null);
  const input = <input className={styles.inputEdit} onChange={updateUsername} value={usernameEdit} type="text" />;
  const guildName = localStorage.getItem('guild');
  
  const getUserData = () => {
    const token = localStorage.getItem('accessToken');
    fetch('https://api-fisher.thefishverse.com/user/profile/', {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            'authorization': `Bearer ${token}`
        }
    })
    .then(response => response.json())
    .then(data => {
        console.log(data);
        setUsernameEdit(data.username);
        setHuntersBalance(data.hunter_coins);
        localStorage.setItem('guild', data.guild.length > 0 ? data.guild : 'None');
        localStorage.setItem('username', data.username);
        localStorage.setItem('metamask', data.metamask_address);
    })
    .catch((error) => {
        console.error(error);
    });
}
  
  useEffect(() => {
    getUserData();
  }, [])

  function editUsername() {
    if(usernameEdit.length != 0) {
      setIsEditing(prevIsEditing => !prevIsEditing);
    }
    if(isEditing == true) {
      const registration = {
          metamask_address: '',
          newsletter: '',
          uuid: '',
          username: usernameEdit,
          email: ''
      };

      fetch('https://api-fisher.thefishverse.com/user/profile/', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'authorization': `Bearer ${token}`
        },
        body: JSON.stringify(registration)
      })
      .then(response => response.json())
      .then(data => {
        if(data.email) {
          localStorage.setItem('username', data.username);
          setUsernameEdit(data.username);
          setError(null);
        } else {
          setError(data.username);
          setIsEditing(true);
        }
      })
      .catch((error) => {
        console.error(error);
    });
    }
  }
  function updateUsername(e) {
    const newUsername = e.target.value;
    setUsernameEdit(newUsername);
  }
  function cancelUsernameChanges() {
    setIsEditing(false);
    setUsernameEdit(username);
    setError(null);
  }

  return (
    <div className={cn(styles.infoContainer)}>
      <h2 className={cn(styles.title)}>
        {guildName != 'None' ? <span className={styles.guild}>[ {guildName} ] </span> : null}
        {isEditing ? input : usernameEdit} <div onClick={editUsername} className={isEditing ? styles.saveButton : styles.editButton}>{isEditing ? 'Save' : editSvg}</div>
        {isEditing ? <div onClick={cancelUsernameChanges} className={isEditing ? styles.saveButton : styles.editButton}>Cancel</div> : null }
      </h2>
      <p className={styles.error}>{error}</p>
      <div className={cn(styles.container)}>
        <div className={cn(styles.levelBarContainer)}>
          <span className={cn(styles.level)}>Level 1</span>
          <div>
              <div className={cn(styles.levelBar)}></div>
              <p>{expSvg}<span>0</span> to next level</p>
          </div>
        </div>
        <div className={cn(styles.rewardContainer)}>
            <ReactTooltip place={"top"} />
            <div data-tip={"Next level reward: 0"} className={styles.tooltipMarker}>?</div>
        </div>
      </div>   
      <div className={cn(styles.container)}>
        <div className={cn(styles.walletAddress)}>
            <h5>{ account ? getNetworkName(chainId) : 'Not available' } address</h5>
            <p>{ account ? account : 'Wallet is not connected' }</p>
        </div>
        <div className={cn(styles.balancesContainer)}>
          <div className={cn(styles.balance)}>
              <h5>FVS Balance</h5>
              <p>{fvs}{active ? formatedBalance : "0.0"}</p>
          </div>
          <div className={cn(styles.huntersBalance)}>
              <h5>SALT Balance</h5>
              {/*<p>{hunter} 0.0</p>*/}
              <p>{salt}0.0</p>
          </div>
          <div className={cn(styles.huntersBalance)}>
              <h5>FRESH Balance</h5>
              {/*<p>{hunter} 0.0</p>*/}
              <p>{fresh}0.0</p>
          </div>
        </div>
      </div>     
    </div>
  );
};

export default AccountInfo;