import {JsonRpcSigner, Web3Provider} from '@ethersproject/providers'
import {AddressZero} from '@ethersproject/constants'
import {Contract} from '@ethersproject/contracts'
import {BigNumber} from "@ethersproject/bignumber";
import {NETWORK_PROVIDER} from "../constants/networks";
import {ethers} from 'ethers';
import {NFT_ADDRESS} from "../constants/addresses";
import {Contract as MultiContract, Provider} from 'ethers-multicall';
import NFT_ABI from '../constants/abis/MetaShooterNFT.json'
import {NFT} from "../constants/interfaces";
import {itemContentIpfsMap} from "../constants/items";

// account is not optional
export function getSigner(library: Web3Provider, account: string): JsonRpcSigner {
    return library.getSigner(account).connectUnchecked()
}

// account is optional
export function getProviderOrSigner(library: Web3Provider, account?: string): Web3Provider | JsonRpcSigner {
    return account ? getSigner(library, account) : library
}

// account is optional
export function getContract(address: string, ABI: any, library: Web3Provider, account?: string): Contract {
    if (address === AddressZero) {
        throw Error(`Invalid 'address' parameter '${address}'.`)
    }

    return new Contract(address, ABI, getProviderOrSigner(library, account) as any)
}

export function calculateGasMargin(value: BigNumber, percent: number): BigNumber {
    return value.mul(BigNumber.from(10000 + (percent * 100))).div(BigNumber.from(10000))
}

export const formatOwnedWallet = (tokenIds: BigNumber[], items: any, contract?: string): NFT[] => {
    let wallet: NFT[] = [];
    for (let i = 0; i < tokenIds.length; i++) {
        let nft = itemContentIpfsMap.get(items[i].tokenUri);
        if (nft){
            const newNft = { ...nft } as NFT;
            newNft.tokenId = tokenIds[i].toNumber();
            if (contract){
                newNft.contract = contract;
            }
            wallet.push(newNft)
        }
    }
    return wallet;
}

export async function getTransactionReceipt(hash: string, chainId: number | null){
    const provider = ethers.getDefaultProvider(chainId ? NETWORK_PROVIDER[chainId] : undefined);
    let receipt = await provider.getTransactionReceipt(hash);

    while (receipt == null){
        receipt = await provider.getTransactionReceipt(hash);
        console.log("no receipt retrying...", receipt, receipt==null)
    }
    return receipt;
}

export async function getTokenIdsFromBoxOpenReceipt(hash: string, chainId: number | null){
    const receipt = await getTransactionReceipt(hash, chainId);
    let items = [];
    for (let i = 0; i < receipt['logs'].length - 1; i++) {
        items.push(BigNumber.from(receipt['logs'][i]['topics'][3]));
    }

    return items;
}

export async function getTokenIdFromClaimReceipt(hash: string, chainId: number | null){
    const receipt = await getTransactionReceipt(hash, chainId);
    return BigNumber.from(receipt['logs'][0]['topics'][3]);
}

export async function getWalletItemsFromTokenIds(tokenIds: BigNumber[], chainId: number | null) {
    if (chainId != null && tokenIds.length > 0){
        const nftContractMulti = new MultiContract(NFT_ADDRESS[chainId], NFT_ABI);
        const callProvider = getCallProvider(chainId, null);

        let calls = [];
        for (let i = 0; i < tokenIds.length; i++) {
            calls.push(nftContractMulti.tokenItem(tokenIds[i]))
        }
        try {
            const callResults: any = await callProvider.all(calls);
            return formatOwnedWallet(tokenIds, callResults, NFT_ADDRESS[chainId]);
        }
        catch (error) {
            console.error("Error getting wallet items from token ids", error)
        }
    }
    return [];
}

export function getProvider(chainId: number | undefined, window:any){
    if (window !== null && typeof window.ethereum !== 'undefined') {
        return new ethers.providers.Web3Provider(window.ethereum);
    } else {
        return ethers.getDefaultProvider(chainId ? NETWORK_PROVIDER[chainId] : undefined);
    }
}

export function getCallProvider(chainId: number | undefined, window:any){
    return new Provider(getProvider(chainId, window), chainId);
}



