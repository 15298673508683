import {useState, useEffect} from 'react';
import {useStakeContract} from "./useContract";
import {formatEther} from "@ethersproject/units";
import {Contract} from "@ethersproject/contracts";
import {BigNumber} from "@ethersproject/bignumber";
import {STAKE_ADDRESS, STAKE_V2_ADDRESS, STAKE_V3_ADDRESS} from "../constants/addresses";


export function useTotalStaked(account?: null | string) {
    const [totalsV3, setTotalsV3] = useState({totalStaked: BigNumber.from(0), totalShares: BigNumber.from(0)});
    const stakeV3Contract = useStakeContract(STAKE_V3_ADDRESS)

    const getTotalStaked = (contract: Contract | null, setTotals: any, account?: null | string) => {
        if (contract && account) {
            try {
                contract.getStakerInfo(account).then((value: any) => {
                    setTotals({totalStaked: value.totalStakeAmount,
                               totalShares: value.totalStakerShares});
                })
            }
            catch (error) {
                console.log("Error getting total staked", account, error)
            }
        }
    }

    useEffect(() => {
        getTotalStaked(stakeV3Contract, setTotalsV3, account)
    }, [account]);


    return {
        totalStaked: +formatEther(totalsV3.totalStaked),
        totalShares: +formatEther(totalsV3.totalShares)
    };
}

